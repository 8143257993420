export const BlackArrowLeft = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="22"
    height="40"
    viewBox="0 0 22 40"
    fill="none"
  >
    <path
      d="M20.2695 1L2.0003 20L20.2695 39"
      stroke="black"
      strokeWidth="2"
      strokeLinecap="round"
    />
  </svg>
);

export const BlackArrowRight = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="21"
    height="40"
    viewBox="0 0 21 40"
    fill="none"
  >
    <path
      d="M1 39L19.2692 20L1 1"
      stroke="black"
      strokeWidth="2"
      strokeLinecap="round"
    />
  </svg>
);
