import { ReactElement } from "react";

type Direction = "left" | "right";
type Props = {
  size?: number;
  direction?: Direction;
  strokeWidth?: number;
};

function getPath(
  direction: Direction,
  width: number,
  height: number,
  strokeWidth: number,
) {
  const strokeOffset = strokeWidth / 2;

  /**
   * [a,b] where:
   * a is the top-left corner
   * b is the top-right corner
   */
  const x = [strokeOffset, width - strokeOffset];
  if (direction === "left") {
    x.reverse();
  }

  // Drawing arrow from top to bottom
  return [
    `M${x[0]} ${strokeOffset}`, // Move pen to $x $y
    `L${x[1]} ${height / 2}`, // Draw line to $x $y
    `L${x[0]} ${height - strokeOffset}`,
  ].join("");
}

export default function Arrow({
  size = 54,
  direction = "right",
  strokeWidth = 2,
}: Props): ReactElement {
  const width = Math.round(size * 0.52);
  const height = size;
  const color = "currentColor";
  // const shadowColor = "var(--custom-arrow-shadow-color, transparent)";

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      fill="none"
      stroke={color}
      // style={{
      //   filter: [
      //     `drop-shadow(0 1px 2px ${shadowColor})`,
      //     `drop-shadow(0 1px 2px ${shadowColor})`,
      //   ].join(" "),
      // }}
    >
      <path
        d={getPath(direction, width, height, strokeWidth)}
        stroke={color}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
      />
    </svg>
  );
}
