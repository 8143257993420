import React, { ReactNode } from "react";
import styles from "./callblockquote.module.css";

type callOnQuoteProps = {
  title?: ReactNode;
  description?: string;
  label?: string;
};

const CallOnQuote: React.FC<callOnQuoteProps> = ({
  label,
  title,
  description,
}: callOnQuoteProps): ReactNode => {
  return (
    (title || description) && (
      <div className={styles.callblockContainer}>
        <div className={styles.callblockTextContainer}>
          {label && <div className={styles.callQuoteLabel}>{label}</div>}
          <div className={styles.callblockTitle}>{title}</div>
          <div className={styles.centeredText}>
            <div className={styles.callblockDescription}>{description}</div>
          </div>
        </div>
      </div>
    )
  );
};

export default CallOnQuote;
