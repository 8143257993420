import { ResolvedTableCUEElement } from "../../elements";
import styles from "./table.module.css";
import TableBody from "./TableBody";
import TableHead from "./TableHead";

/**
 * Table features:
 * - If NO row header and NO column header set, first row will be header automatically.
 * - Alternate rows will have background colors.
 */
const Table = ({ element }: { element: ResolvedTableCUEElement }) => {
  const dataString = element.additionalFields.tableeditor?.value;
  const hasRowHeader = element.additionalFields.rowheader?.value;
  const hasColHeader = element.additionalFields.columnheader?.value;

  if (!dataString) {
    return;
  }
  const data = JSON.parse(dataString) as string[][];

  if (hasColHeader && !hasRowHeader) {
    return (
      <table className={styles.tableStyles}>
        <TableBody bodyData={data} hasColHeader={hasColHeader} />
      </table>
    );
  }

  return (
    <table className={styles.tableStyles}>
      <TableHead rowData={data[0]} />
      <TableBody bodyData={data.slice(1)} hasColHeader={hasColHeader} />
    </table>
  );
};

export default Table;
