import { ReactNode, ReactElement } from "react";
import styles from "./textlink.module.css";

export type Props = {
  children: ReactNode;
  href: string;
  target?: string;
};

/**
 *
 * Description: [Description of what the component does]
 * @param props - The props required by the component (if any)
 * @returns The rendered React element
 */
export default function TextLink({
  children,
  href,
  target,
}: Props): ReactElement {
  return (
    <a className={styles.cta} href={href} target={target}>
      {children}
    </a>
  );
}
