import cx from "classnames";
import { ReactNode } from "react";
import styles from "./listgroup.module.css";

type ListGroupProps = {
  items: {
    text: string;
    url: string;
  }[];
  /* This highlights the activeTitle from the list */
  activeTitle?: string;
  onClick?: (e: React.MouseEvent<HTMLAnchorElement>) => void;
};

/**
 * This is our list group component. It aims to display a series of content.
 */
function ListGroup({ items, activeTitle, onClick }: ListGroupProps): ReactNode {
  return (
    <div className={styles.listGroup} data-layout-position="subnavbar">
      {items.map(({ text, url }, index) => (
        <a
          href={url}
          onClick={onClick}
          className={cx(
            styles.listGroupItem,
            activeTitle === text && styles.itemSelected,
          )}
          key={index}
        >
          {text}
        </a>
      ))}
    </div>
  );
}

export default ListGroup;
