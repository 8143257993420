import { Fragment, ReactElement, ReactNode } from "react";
import { useCarousel } from "../../../hooks/";
import styles from "./carousel.module.css";
import cx from "classnames";

type Props = {
  itemsLength: number;
  nextNav: ReactElement;
  prevNav: ReactElement;
  children: ReactNode;
  noItemsInFrame: number;
  carouselContainerStyle?: string;
  arrowStyle?: {
    next: string;
    prev: string;
  };
};

/**
 * Utility Carousel Component with nav items on the side.
 */
function Carousel({
  itemsLength,
  nextNav,
  prevNav,
  carouselContainerStyle,
  arrowStyle,
  noItemsInFrame,
  children,
}: Props) {
  const {
    TouchHandlers,
    refPassthrough,
    hasSlideMoved,
    hasReachedEnd,
    next,
    prev,
  } = useCarousel(itemsLength, noItemsInFrame);

  return (
    <Fragment>
      <div className={styles.carouselContainer}>
        <div
          className={cx(styles.carousel, carouselContainerStyle)}
          {...TouchHandlers}
          ref={refPassthrough}
        >
          {children}
        </div>
      </div>
      <div className={styles.navigation}>
        <div
          className={cx(
            styles.prev,
            arrowStyle?.prev,
            !hasSlideMoved && styles.hidden,
          )}
        >
          <button onClick={() => prev()}>{prevNav}</button>
        </div>
        <div
          className={cx(
            styles.next,
            arrowStyle?.next,
            hasReachedEnd && styles.hidden,
          )}
        >
          <button onClick={() => next()}>{nextNav}</button>
        </div>
      </div>
    </Fragment>
  );
}

export default Carousel;
