import { ConfigHeader } from "../../types/config";
import * as Icons from "../icons/socialIcon";

export const configHeader: ConfigHeader = {
  megaMenu: {
    newsletter: {
      text: "Your life. Your rules. Your style. We’ve got you covered. Sign up for our newsletter for the latest news from Her World.",
      buttonText: "SIGN UP",
      url: "/newsletter",
    },
    closeButton: {
      type: "icon",
      placement: "right",
      TextOrIcon: (
        <svg
          width="20"
          height="20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M19 1 .615 19.385M19 19.385.615 1"
            stroke="#000"
            strokeWidth="1.5"
          />
        </svg>
      ),
    },
  },
  socialMedia: {
    target: "_blank",
    icons: [
      {
        icon: Icons.facebookIcon,
        name: "Facebook",
        href: "http://www.facebook.com/sg.Herworld",
      },
      {
        icon: Icons.instagramIcon,
        name: "Instagram",
        href: "http://instagram.com/herworldsingapore",
      },
      {
        icon: Icons.linkedinIcon,
        name: "LinkedIn",
        href: "http://www.linkedin.com/company/herworldsingapore",
      },
      {
        icon: Icons.tiktokIcon,
        name: "Tiktok",
        href: "https://www.tiktok.com/@herworldsingapore",
      },
    ],
  },
};
