import DataLoader from "dataloader";

import { AffiliatesApiResponse } from "../types/ServerCustomContext";
import { fetcher } from "./swr";

const amazonPricingDataLoader = new DataLoader(
  async (keys) => {
    const response = (await fetcher(
      `/_plat/api/affiliates?asin=${keys.join(",")}`,
    )) as AffiliatesApiResponse;

    return keys.map((key) => {
      return response?.results?.find((item) => item.ASIN === key);
    });
  },
  { maxBatchSize: 10 },
);

export default amazonPricingDataLoader;
