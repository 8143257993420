//import modules from design system
import { configSearch } from "@pub/config";
import { Dropdown, Loader, Typography } from "@sphtech/dmg-design-system";
import SearchForm from "@src/app/components/Modules/SearchForm/SearchForm";
import SearchResults from "@src/app/layouts/Search/SearchResults";
import { TeaserDataApiResponse } from "@src/app/types/ServerCustomContext";
import { FetchError } from "@src/app/utils/swr";
import { LOAD_MORE_TEXT } from "@src/pubs/common/config";
//import classnames
import cx from "classnames";
//import react
import { Fragment, ReactNode } from "react";

import styles from "./search.module.css";

const { Heading } = Typography;

type Props = {
  isLoading?: boolean;
  searchTerm?: string;
  searchSetting: string;
  updateSearchSetting: React.ChangeEventHandler<HTMLSelectElement>;
  error?: FetchError;
  data?: TeaserDataApiResponse[];
  loadMore?: () => void;
  isLoadingMore: boolean;
  onSearch: React.ComponentProps<typeof SearchForm>["onSubmit"];
};

function SearchLayout({
  data,
  onSearch,
  searchTerm,
  searchSetting,
  updateSearchSetting,
  loadMore,
  isLoading,
  isLoadingMore,
  error,
}: Props): ReactNode {
  const latestApiData = data?.at(-1);
  const firstApiData = data?.[0];

  const searchSettingOptions = [
    { label: "RELEVANCE", value: "score" },
    { label: "LATEST", value: "latest" },
  ];

  return (
    <Fragment>
      {/* {SearchBox} */}
      <section
        className={cx(
          styles.bodyContainer,
          styles.searchBoxContainer,
          searchTerm === "" && styles.searchBoxInitialMarginBottom,
        )}
      >
        <SearchForm value={searchTerm} onSubmit={onSearch} />
      </section>

      {/* {Result Heading } */}
      {latestApiData?.total !== undefined && (
        <section className={cx(styles.bodyContainer)}>
          <div className={styles.headingContainer}>
            <Heading.SubHeading3>
              {`Found ${
                latestApiData.total === 1
                  ? "one result"
                  : `${latestApiData.total} results`
              }`}
            </Heading.SubHeading3>
            <Dropdown
              label="Sort by:"
              name="search"
              id="search"
              value={searchSetting}
              onChange={updateSearchSetting}
              options={searchSettingOptions}
            />
          </div>
          {firstApiData?.results?.length === 0 && (
            <div className={styles.gutterBottom}>
              <Typography.Body>
                {`Sorry, we couldn't find anything for “${searchTerm}”.`}
              </Typography.Body>
            </div>
          )}
        </section>
      )}

      {isLoading && (
        <div className={cx(styles.bodyContainer, styles.gutterBottom)}>
          <Loader label={configSearch.loadMoreText || LOAD_MORE_TEXT} />
        </div>
      )}

      {!!error && (
        <div className={cx(styles.bodyContainer, styles.gutterBottom)}>
          <Typography.Body>
            {error.statusCode === 400
              ? "Your search request cannot be processed."
              : "An error occured! Please try again."}
          </Typography.Body>
        </div>
      )}

      {/* {Search Results Infinite scroll} */}
      {data?.map((data, index, all) => {
        const isLast = all.length - 1 === index;
        if (!data?.results?.length) {
          return;
        }
        return (
          <SearchResults
            key={index}
            data={data.results}
            showLeaderboard={index > 0}
            loadMore={isLast ? loadMore : undefined}
            isLoadingMore={isLast && isLoadingMore}
          />
        );
      })}
    </Fragment>
  );
}

export default SearchLayout;
