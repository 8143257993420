import { configTeaser } from "@pub/config";
import placeholders from "@pub/placeholders";
import { AnchorBlock, LatestArticle } from "@sphtech/dmg-design-system";
import { ContentHubData } from "@src/app/types/ServerCustomContext";
import cx from "classnames";

import styles from "../contenthub.module.css";
type Props = {
  data: Omit<ContentHubData, "metaData">;
  from?: number;
  limit?: number;
};

export default function ContentHubBlockB({ data, from = 0, limit = 7 }: Props) {
  const topStories = data.topStories && data.topStories.slice(from, limit);
  if (!topStories?.length) {
    return null;
  }
  return (
    <section
      className={cx(
        styles.bodyContainer,
        styles.contenthubLatestStories,
        styles.contentBlockB,
      )}
    >
      <div className={styles.latestArticleContainer}>
        <div className={styles.latestArticleLayout}>
          <AnchorBlock
            {...topStories[0]}
            mobileCrop={configTeaser.anchorBlock?.mobileCrop}
          />
        </div>
      </div>

      <div
        className={cx(styles.gutterHorizontal, styles.contentBlockContainer)}
      >
        <div className={styles.contentBlockLayout}>
          {topStories.slice(1).map((teaser, index: number) => (
            <div key={index} className={styles.contentBlockItem}>
              <LatestArticle
                key={index}
                {...teaser}
                mode="landscape"
                placeholders={placeholders}
              />
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}
