import {
  BlackArrowLeft,
  BlackArrowRight,
  PlayButtonWhite,
  whiteArrowLeft,
  whiteArrowRight,
} from "@app/theme/icons/commonIcons";
import { configHome } from "@pub/config";
import placeholders from "@pub/placeholders";
import { Ads, Arrow, VideoGalleryLandscape } from "@sphtech/dmg-design-system";
import cx from "classnames";
import { ReactNode } from "react";

import styles from "../homelayout.module.css";
import { HomeLayoutProps } from "./utils";

type Props = {
  data: HomeLayoutProps["data"];
};

export default function HomeVideoGalleryLandscape({ data }: Props): ReactNode {
  const ContentVideoBlock = data.contentBlock.filter(
    (item) => item.type === "videoblock",
  );

  return (
    !!ContentVideoBlock.length &&
    ContentVideoBlock[0] &&
    !!ContentVideoBlock[0].items.length && (
      <div
        className={cx(
          styles.backgroundFill,
          styles.gutter,
          styles.videoGalleryLandscapeContainer,
          styles.videoGalleryColorOverride,
        )}
      >
        <div className={styles.bodyContainer}>
          <VideoGalleryLandscape
            title={ContentVideoBlock[0].title}
            items={ContentVideoBlock[0].items.map((item) => {
              const crop =
                item.image?.crops.landscape || placeholders.landscape;
              return {
                ...crop,
                videoUrl: item.embedUri,
                sections: item.sections,
                caption: item.title,
                captionUrl: item.path,
                description: item.description,
              };
            })}
            heading={{
              leftIcon: <Arrow size={22} direction="left" />,
              rightIcon: <Arrow size={22} />,
              withDivider: configHome.headingDivider,
              hasArrows: configHome.videoGalleryLandscape?.heading?.hasArrows,
            }}
            spotLight={{
              leftIcon: whiteArrowLeft,
              rightIcon: whiteArrowRight,
              playIcon: PlayButtonWhite,
              actionElement: <div></div>,
            }}
            mobileLoopingArrowsBelow={{
              showArrows:
                configHome.videoGalleryLandscape?.spotLight
                  ?.mobileLoopingArrowsBelow,
              leftIcon: <Arrow size={22} direction="left" />,
              rightIcon: <Arrow size={22} />,
            }}
            strip={{
              leftIcon: configHome.videoGalleryLandscape?.strip?.icon ? (
                <Arrow direction="left" />
              ) : (
                BlackArrowLeft
              ),
              rightIcon: configHome.videoGalleryLandscape?.strip?.icon ? (
                <Arrow />
              ) : (
                BlackArrowRight
              ),
            }}
            cta={{
              name:
                configHome.videoGalleryLandscape?.viewMore?.text ||
                "VIEW ALL VIDEOS",
              url:
                configHome.videoGalleryLandscape?.viewMore?.href || "/videos",
              icon: configHome.viewMore?.icon,
            }}
            Imu={<Ads.AdsSideBar />}
            hasPagination={configHome.videoGalleryLandscape?.hasPagination}
          />
        </div>
      </div>
    )
  );
}
