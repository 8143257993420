import { ReactNode } from "react";
import styles from "./heading.module.css";

type HeadingElement = "h1" | "h2" | "h3" | "h4" | "h5";

type Props = {
  CustomElement?: HeadingElement;
  children: ReactNode;
};

type HeadingReturnType = ({ children }: Props) => ReactNode;

function createHeading(
  DefaultElement: HeadingElement,
  className: string,
): HeadingReturnType {
  return ({
    children,
    CustomElement: Element = DefaultElement,
  }: Props): ReactNode => (
    <Element className={`${className} ${styles.base}`}>{children}</Element>
  );
}

// create comments so that it easier for the devs to identify
/** alias to H1 */
export const SectionTitle = createHeading("h1", styles.sectionTitle);
/** alias to H1 with H2 styling*/
export const Title = createHeading("h1", styles.title);
/** alias to H2*/
export const SubSectionTitle = createHeading("h2", styles.sectionTitle);
/** alias to H2 with H3 styling */
export const SubHeading = createHeading("h2", styles.subHeading1);
/** alias to H3 */
export const SubHeading2 = createHeading("h3", styles.subHeading2);
/** alias to H4 */
export const SubHeading3 = createHeading("h4", styles.subHeading3);
/** alias to H5 */
export const SubHeading4 = createHeading("h5", styles.subHeading4);
