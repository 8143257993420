import { PUBLIC_PREVIEW_MUST_NOT_CLAUSE, SOURCE_FILTER } from "@app/constants";
import { TeaserData } from "@app/types/ServerCustomContext";
import { configCaas } from "@pub/config";

export type SocialVideo = {
  videoUrl: string;
  title?: string;
};

export type VideoLandingData = {
  videoLandingTitle?: string;
  videoLists: {
    title?: string;
    items: (TeaserData | SocialVideo)[];
    anchor?: string;
  }[];
};

export function isSocialVideo(
  teaser: TeaserData | SocialVideo,
): teaser is SocialVideo {
  return !!Object.prototype.hasOwnProperty.call(teaser, "videoUrl");
}

export const mapVideoStoryTeaserToBlock = (teaser: {
  path: string;
  title?: string;
  image?: TeaserData["image"];
}) => ({
  caption: teaser.title || "Untitled Video Post",
  captionUrl: teaser.path,
  image: teaser.image?.crops.landscape
    ? {
        src: teaser.image.crops.landscape.src,
        alt: teaser.image.alt,
        srcWidth: 315,
        srcHeight: 210,
      }
    : undefined,
});

export const genCaasVideoStoryReq = (
  size: number = 9,
  curatedIds?: string[],
) => {
  return {
    index: configCaas.index,
    search: {
      size,
      sort: [
        {
          // updated is the first published date
          "data.context.updated": {
            order: "desc",
            unmapped_type: "boolean",
          },
        },
      ],
      _source: SOURCE_FILTER,
      query: {
        bool: {
          filter: [
            {
              bool: {
                minimum_should_match: 1,
                should: ["videoStory"].map((type) => ({
                  match: {
                    "data.context.type": type,
                  },
                })),
              },
            },
          ],
          must_not: [
            {
              ids: { values: curatedIds },
            },
            PUBLIC_PREVIEW_MUST_NOT_CLAUSE,
          ],
        },
      },
    },
  };
};
