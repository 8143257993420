import { ReactElement, ReactNode, useState } from "react";
import styles from "./embeddedaction.module.css";
import * as Typography from "../../Element/Typography";
import Icon from "../../Element/Icon/icon";
import EmbeddedShare from "../EmbeddedShare/EmbeddedShare";

type Props = {
  caption: ReactNode;
  onFullScreen: () => void;
  shareIcon: ReactNode;
  fullScreenIcon: ReactNode;
  pageinationLabel: string;
  shareUrl: string;
  shareMedia?: string[];
};

const { Pagination } = Typography;

/**
 * EmbeddedAction is a component that renders the action bar for single EmbeddedImage
 */
export default function EmbeddedAction({
  caption,
  pageinationLabel,
  shareIcon,
  onFullScreen,
  fullScreenIcon,
  shareUrl,
  shareMedia,
}: Props): ReactElement {
  const [showShare, setShowShare] = useState(false);

  const handleOnShare = () => {
    setShowShare((prev) => (prev ? false : true));
  };

  const shareAction = (
    <div
      className={styles.shareAction}
      style={{
        visibility: showShare ? "visible" : "hidden",
      }}
    >
      <EmbeddedShare url={shareUrl} open={showShare} media={shareMedia} />
    </div>
  );

  return (
    <div className={styles.statusBar}>
      {caption}
      <div className={styles.actionBar}>
        <Pagination size="small">{pageinationLabel}</Pagination>
        <div className={styles.shareButton}>
          <button onClick={handleOnShare}>
            <Icon>{shareIcon}</Icon>
          </button>
          {shareAction}
        </div>
        <button onClick={onFullScreen}>
          <Icon>{fullScreenIcon}</Icon>
        </button>
      </div>
    </div>
  );
}
