import { ReactNode, useState } from "react";
import styles from "./newsletter.module.css";
import * as Typography from "../../Element/Typography";
import Button from "../../Element/Button";
import { Disclaimer } from "../../Element/Typography";

const { SubHeading2 } = Typography.Heading;
const Label = Typography.Label;

export type NewsletterProps = {
  emailPlaceHolder: {
    label?: ReactNode;
    placeholder?: string;
  };
  backgroundImage: string;
  newsletterTitle?: ReactNode;
  newsletterBody: ReactNode;
  newsletterCtaText?: ReactNode;
  afterTextInput?: ReactNode;
  fieldValue?: string;
  value?: string;
  onSubmit: (
    email: string,
    isChecked: boolean,
  ) => Promise<{
    ok: boolean;
    message: string;
  }>;
};

export default function Newsletter({
  emailPlaceHolder,
  newsletterTitle,
  newsletterBody,
  newsletterCtaText,
  backgroundImage,
  afterTextInput,
  onSubmit,
}: NewsletterProps) {
  const [fieldValue, setFieldValue] = useState("");
  const [isDirty, setIsDirty] = useState(false);

  const [error, setError] = useState("");
  const [serverMessage, setServerMessage] = useState("");
  const [isChecked, setIsChecked] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const validateEmail = (email: string): boolean => {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return regex.test(email);
  };

  return (
    <div className={styles.newsletterContainer}>
      {/* Background image is positioned absolute */}
      {backgroundImage && (
        <img
          src={backgroundImage}
          alt=""
          className={styles.newsletterImage}
          loading={import.meta.env.VITE_SKIP_LAZY ? undefined : "lazy"}
        />
      )}

      <div className={styles.contentContainer}>
        {newsletterTitle && <SubHeading2>{newsletterTitle}</SubHeading2>}
        <div className={styles.newsletterBody}>
          <p>{newsletterBody}</p>
        </div>

        <div className={styles.formContainer}>
          <div>
            {emailPlaceHolder.label && (
              <label>
                <Label>{emailPlaceHolder.label}</Label>
              </label>
            )}
          </div>
          <div className={styles.inputContainer}>
            <form
              noValidate
              onSubmit={async (e) => {
                e.preventDefault();
                setServerMessage("");
                if (!validateEmail(fieldValue)) {
                  setIsDirty(true);
                  setError("Please enter a valid email");
                  return; // Don't call onSubmit if the email is not valid
                }
                setIsSubmitting(true); // Set form to submitting state
                try {
                  const response = await onSubmit(fieldValue, isChecked);
                  setServerMessage(response.message);
                  if (response.ok) {
                    setFieldValue("");
                    setIsDirty(false);
                  }
                } catch (e) {
                  setServerMessage("An unexpected error occurred.");
                } finally {
                  setIsSubmitting(false);
                }
              }}
            >
              <div className={styles.inputButtonContainer}>
                <input
                  placeholder={emailPlaceHolder.placeholder}
                  value={fieldValue}
                  onChange={(e) => {
                    setFieldValue(e.target.value);
                    if (!validateEmail(e.target.value)) {
                      setError("Please enter a valid email");
                    } else {
                      setError("");
                    }
                  }}
                  className={styles.emailInput}
                  type="email"
                />

                <span className={styles.signupButton}>
                  <Button
                    variant="secondary"
                    type="submit"
                    disabled={isSubmitting}
                  >
                    {newsletterCtaText}
                  </Button>
                </span>
              </div>
            </form>
          </div>
        </div>
        {afterTextInput && (
          <div className={styles.afterTextInput}>
            <p>{afterTextInput}</p>
          </div>
        )}
        {error && isDirty && <Disclaimer>{error}</Disclaimer>}
        {serverMessage && <Disclaimer>{serverMessage}</Disclaimer>}
        <label className={styles.checkboxContainer}>
          <input
            type="checkbox"
            name="marketingCheckbox"
            checked={isChecked}
            onChange={(e) => {
              setIsChecked(e.target.checked);
            }}
          />
          Yes, I would also like to receive SPH Media Group&apos;s{" "}
          <abbr
            style={{ textDecoration: "none" }}
            title="SPH Media Limited, its related corporations and affiliates as well as their agents and authorised service providers"
          >
            ⓘ
          </abbr>{" "}
          marketing and promotions.
        </label>
        <p className={styles.termsAndConditions}>
          By submitting my information above and clicking &apos;Sign up&apos;, I
          confirm I understand and accept{" "}
          <a
            href="https://www.sph.com.sg/tnc/website"
            className={styles.tncLink}
            target="_blank"
          >
            SPH Media Terms & Conditions
          </a>{" "}
          and{" "}
          <a
            href="https://www.sph.com.sg/tnc/privacy"
            className={styles.tncLink}
            target="_blank"
          >
            Privacy Policy
          </a>{" "}
          as amended from time to time.
        </p>
      </div>
    </div>
  );
}
