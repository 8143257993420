import { ReactNode } from "react";
import styles from "./embededimage.module.css";
import EmbeddedAction from "../../Block/EmbeddedAction/EmbeddedAction";
import ResponsiveImage from "../../Element/ResponsiveImage";
import { ImageCrop } from "../../Utility/dataTypes";

export type Props = {
  crop: ImageCrop;
  heading?: ReactNode;
  caption?: ReactNode;
  actionBar: React.ComponentProps<typeof EmbeddedAction>;
};

export default function EmbededImage({
  heading,
  caption,
  actionBar,
  crop,
}: Props) {
  return (
    <div className={styles.embeddedImageContainer}>
      {heading}
      <button onClick={actionBar.onFullScreen}>
        <ResponsiveImage {...crop} displayWidth={655} />
      </button>
      <div className={styles.statusBar}>
        {caption && <div>{caption}</div>}
        <div className={styles.actionBar}>
          <EmbeddedAction
            caption={actionBar.caption}
            pageinationLabel={actionBar.pageinationLabel}
            shareIcon={actionBar.shareIcon}
            onFullScreen={actionBar.onFullScreen}
            fullScreenIcon={actionBar.fullScreenIcon}
            shareUrl={actionBar.shareUrl}
            shareMedia={actionBar.shareMedia}
          />
        </div>
      </div>
    </div>
  );
}
