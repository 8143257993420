import { useEffect, useState } from "react";
import styles from "./index.module.css";
import {
  embedScriptRecord,
  SOCIAL_MEDIA,
  convertToEmbed,
  getAspectRatio,
  setupResizeListener,
} from "./utils";
import cx from "classnames";
import TwitterTweetEmbed from "../TwitterEmbed/TwitterTweetEmbed";
import YouTubeFacade from "./YouTubeFacade";
import loadScript from "../../Utility/LoadScript";
import { makeSvgDataSrc } from "../../Utility/placeholder";

// We listen for resize event outside of the react lifecycle
// - no need for additional state and ref, more similar to how 3rd party scripts do it
setupResizeListener();

const ResponsiveIframe: React.FC<{
  /** the url of the iframe, can automatically convert common video urls into embed urls, will also guess aspect ratio based off this unless srcWidth and srcHeight is given */
  src: string;
  /** iframe title attribute */
  title?: string;
  /** loading for browser nativ lazy loading, specify "eager" for YouTube videos that do not require facade */
  loading?: "lazy" | "eager";
  /** allow to amend embed code path for certain hostnames */
  embedPathAmends?: Record<string, string>;
}> = ({ src, title, loading, embedPathAmends }) => {
  const [embedSrc, social] = convertToEmbed(src, embedPathAmends, loading);
  const [showYTFacade, setShowYTFacade] = useState(
    social === SOCIAL_MEDIA.YOUTUBE && loading !== "eager",
  );
  const resovledCustomScript = embedScriptRecord[social];
  const [loadingEmbedScript, setLoadingEmbedScript] =
    useState(!!resovledCustomScript);

  useEffect(() => {
    if (resovledCustomScript) {
      loadScript(resovledCustomScript.script, true)
        .then(() => {
          if (resovledCustomScript.callback) {
            resovledCustomScript.callback();
          }
          setLoadingEmbedScript(false);
          return;
        })
        .catch((e) => {
          setLoadingEmbedScript(false);
          console.error(e);
        });
    }
  }, [resovledCustomScript]);

  if (import.meta.env.VITE_DISABLE_EMBEDS) {
    return (
      <img
        src={makeSvgDataSrc({
          height: 100,
          width: getAspectRatio(src, social) * 100,
          text: "DISABLE_EMBEDS",
        })}
        className={cx(styles.base, styles[social])}
      />
    );
  }

  if (social === SOCIAL_MEDIA.TWITTER) {
    return <TwitterTweetEmbed tweetId={embedSrc.split("/").pop()} />;
  }

  if (social === SOCIAL_MEDIA.FACEBOOK) {
    return (
      <div
        className={cx(
          styles.facebookContainer,
          loadingEmbedScript && styles.loadingEmbedScript,
        )}
      >
        <div className="fb-post" data-href={src} data-show-text="false" />
      </div>
    );
  }

  if (showYTFacade) {
    return (
      <YouTubeFacade
        src={src}
        title={title}
        onClick={() => {
          setShowYTFacade(false);
        }}
      />
    );
  }

  return (
    <iframe
      className={cx(styles.base, styles[social])}
      title={title}
      //yes this is deprecated and there is no straight forward solution to hide the scroll bar
      scrolling="no"
      src={embedSrc}
      style={{
        aspectRatio: getAspectRatio(src, social),
      }}
      allowFullScreen
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share;"
      loading={loading}
    />
  );
};

export default ResponsiveIframe;
