import { Helmet } from "react-helmet-async";

export default AnalyticsGrapeshot;

function AnalyticsGrapeshot({ grapeshots }: { grapeshots: string[] }) {
  return (
    <Helmet>
      <meta
        id="grapeshot"
        name="grapeshot_tag"
        content={grapeshots.join(",")}
      />
      <script type="text/javascript">
        {`window.gpt_grapeshots = ${JSON.stringify(grapeshots)};`}
      </script>
    </Helmet>
  );
}
