//import modules from design system
import { AnchorBlock, ExploreMore } from "@sphtech/dmg-design-system";
import { TeaserData } from "@src/app/types/ServerCustomContext";
import { ReactNode } from "react";

import CategoryLayout from "../Category/CategoryLayout";

type Props = {
  data: {
    tagSlug?: string;
    name: string;
    subHeading?: string;
    anchorBlock?: React.ComponentProps<typeof AnchorBlock>;
    latestArticle?: TeaserData[];
    exploreMore?: React.ComponentProps<typeof ExploreMore>;
    mainContentBlock?: TeaserData[];
    lb1?: ReactNode;
    imu1?: ReactNode;
    imu2?: ReactNode;
  };
};

function TagLayout({ data }: Props): ReactNode {
  return <CategoryLayout data={data} />;
}

export default TagLayout;
