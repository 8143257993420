import cx from "classnames";
import Button from "../../Element/Button";
import Icon from "../../Element/Icon/icon";
import Logo from "../../Element/Logo/Logo";
import * as Typography from "../../Element/Typography";
import styles from "./navbargrid.module.css";
import { NavigationProps } from "./types";

const { NavLink, Heading } = Typography;

type NavbarGridProps = {
  navObject: NavigationProps;
  sticky: boolean;
};

const NavbarGrid = ({ navObject, sticky }: NavbarGridProps) => {
  const {
    LogoSource,
    routes,
    SearchIcon,
    MenuIcon,
    toggleSidebar,
    ArticleTitle,
  } = navObject;

  return (
    <div className={styles.persistent}>
      <div
        className={cx(
          styles.header,
          styles.headerTemplate,
          sticky && styles.sticky,
          ArticleTitle && styles.title,
        )}
      >
        {/* grid-area: logo; */}
        <div className={cx(styles.areaLogo, styles.logo)}>
          <Logo
            href="/"
            src={LogoSource}
            width="100%"
            height="100%"
            alt="logo"
          />
        </div>

        {/* grid-area: pri; */}
        {(!sticky || !ArticleTitle) && (
          <div className={cx(styles.areaPri, styles.primary_items)}>
            {routes.primaryLinks.map((item, index) => {
              if (item.as === "button") {
                return (
                  <Button
                    key={index}
                    variant="primary"
                    href={item.url}
                    target={item.target}
                  >
                    {item.name}
                  </Button>
                );
              }
              return (
                <NavLink key={index} href={item.url}>
                  {item.name}
                </NavLink>
              );
            })}
          </div>
        )}

        {/* grid-area: pri; */}
        {sticky && ArticleTitle && (
          <div className={cx(styles.areaPri, styles.article_item)}>
            <Heading.SubHeading4>{ArticleTitle}</Heading.SubHeading4>
          </div>
        )}

        {/* grid-area: util; */}
        {!!routes.utlityLinks.length && (
          <div className={cx(styles.areaUtil, styles.utlity_items)}>
            {routes.utlityLinks.map((item, index) => {
              if (item.as === "text") {
                return (
                  <NavLink key={index} href={item.url}>
                    {item.name}
                  </NavLink>
                );
              }
              return (
                <Button
                  key={index}
                  variant="primary"
                  href={item.url}
                  target={item.target}
                >
                  {item.name}
                </Button>
              );
            })}
          </div>
        )}

        {/* grid-area: search; */}
        <div className={styles.areaSearch}>
          <a
            href={routes.searchBarLink.url}
            title={routes.searchBarLink.name}
            className={styles.searchIcon}
          >
            <Icon>{SearchIcon}</Icon>
          </a>
        </div>

        {/* grid-area: menu; */}
        <div className={styles.areaMenu}>
          <button
            onClick={toggleSidebar}
            className={styles.hamburgerMenuBtnIcon}
            title="Menu"
          >
            <Icon>{MenuIcon}</Icon>
          </button>
        </div>
      </div>
    </div>
  );
};

export default NavbarGrid;
