import { TeaserData } from "@app/types/ServerCustomContext";
import AppExploreMore, {
  AppExploreMoreProps,
} from "@components/Modules/AppExploreMore/AppExploreMore";
import {
  configCategory,
  configExploreMore,
  configLatestStory,
  configTeaser,
} from "@pub/config";
import placeholders from "@pub/placeholders";
import { Ads, Divider, LatestArticle } from "@sphtech/dmg-design-system";
import cx from "classnames";
import { ReactNode } from "react";

import styles from "./category.module.css";

type Props = {
  data: {
    exploreMore?: AppExploreMoreProps;
    mainContentBlock?: TeaserData[];
    lb1?: ReactNode;
    imu?: ReactNode;
  };
};

export default function CategoryStoryListingLayout({ data }: Props): ReactNode {
  if (data.mainContentBlock && data.mainContentBlock.length > 0) {
    return (
      <section className={cx(styles.bodyContainer, styles.gutterBottom)}>
        <div className={styles.mainContentBlockContainer}>
          {/* Contains list of LatestArticle */}
          <div
            className={cx(
              styles.mainContentBlockLayout,
              styles[configLatestStory.mainContentBlockDisplay],
            )}
          >
            {data.mainContentBlock.map((teaser, index) => (
              <div
                key={index}
                className={cx(
                  styles.mainContentBlockItem,
                  styles[configLatestStory.mainContentBlockMode],
                )}
              >
                <LatestArticle
                  key={index}
                  {...teaser}
                  mode={configLatestStory.mainContentBlockMode}
                  portraitImageWidth={configLatestStory.portraitImageWidth}
                  landscapeImageWidth={configLatestStory.landscapeImageWidth}
                  placeholders={placeholders}
                  displayPublishDate={configTeaser.publishDate}
                />

                {configCategory.adPositioning &&
                  index === configCategory.adPositioning && (
                    <div className={styles.mobileAdvertisement}>
                      <Ads.AdsSideBarMobile />
                    </div>
                  )}
              </div>
            ))}
          </div>

          {/* Contains Ads */}
          <div className={styles.sidebar}>
            {data.exploreMore && (
              <AppExploreMore
                {...data.exploreMore}
                headingText={configExploreMore.heading}
                render={(children) => (
                  <div className={styles.exploreMoreContainer}>
                    <Divider size="full" orientation="horizontal" />
                    {children}
                    <Divider size="full" orientation="horizontal" />
                  </div>
                )}
              />
            )}

            {configCategory.adPositioning ? (
              <Ads.AdsSideBarDesktop />
            ) : (
              <Ads.AdsSideBar />
            )}
          </div>
        </div>
      </section>
    );
  }
}
