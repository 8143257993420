import { ReactElement, ReactNode } from "react";
import cx from "classnames";
import { useCarousel } from "../../../hooks";
import * as Typography from "../../Element/Typography";
import Icon from "../../Element/Icon/icon";
import { SectionSubHeading } from "../../Block/SectionHeading/SectionHeading";

import { videoGalleryItem } from "../../Block/VideoGallery/types";

import styles from "./videogalleryportrait.module.css";
import { VideoGalleryPortraitSmall as Gallery } from "../../Block/VideoGallery/VideoGallery";

type Props = {
  title: string;
  description: string;
  items: Array<videoGalleryItem>;
  cta?: {
    name: string;
    url: string;
    icon?: ReactNode;
  };
  imgWidth: string;
  imgHeight: string;
  noOfItemsInView: number;
  leftIcon: ReactElement;
  rightIcon: ReactElement;
  withDivider?: boolean;
};

const { Pagination, TextLink } = Typography;

/**
 * Video Gallery Portrait
 * Description: Render items inside the carousel with a title and left and right arrow. This component use VideoGallery Portrait block.
 */
export default function VideoGalleryPortrait({
  title,
  description,
  items,
  leftIcon,
  rightIcon,
  noOfItemsInView,
  cta,
  withDivider,
}: Props) {
  const {
    TouchHandlers,
    refPassthrough,
    next,
    prev,
    hasReachedEnd,
    hasSlideMoved,
  } = useCarousel(items.length, noOfItemsInView, false, true);

  return (
    <div className={styles.container}>
      <div className={styles.sectionHeadingGutter}>
        {title && (
          <SectionSubHeading
            title={title}
            description={description}
            withDivider={withDivider}
          />
        )}
      </div>
      <div className={styles.carouselItemsContainer}>
        <div
          className={cx(
            styles.itemsContainer,
            items.length > 3 && styles.itemsContainerMUpScroll,
          )}
          {...TouchHandlers}
          ref={refPassthrough}
        >
          {items.map(({ ...item }, index: number) => (
            <Gallery
              item={item}
              key={index}
              beforeTitle={
                <Pagination size="small">
                  {index + 1}/{items?.length}
                </Pagination>
              }
              afterTitle={"watch now"}
            />
          ))}
        </div>
        <button
          onClick={() => prev()}
          className={styles.prev}
          style={{
            display: hasSlideMoved ? "flex" : "none",
          }}
        >
          <Icon>{leftIcon}</Icon>
        </button>
        <button
          onClick={() => next()}
          className={styles.next}
          style={{
            display: hasReachedEnd ? "none" : "flex",
          }}
        >
          <Icon>{rightIcon}</Icon>
        </button>
      </div>
      <div className={styles.ctaContainer}>
        {cta && (
          <TextLink href={cta.url} target="_blank">
            {cta.name}
            {cta.icon ?? (
              <Icon>
                <>{cta.icon}</>
              </Icon>
            )}
          </TextLink>
        )}
      </div>
    </div>
  );
}
