import { Fragment, ReactElement, ReactNode, useEffect, useState } from "react";
import { useCarousel } from "../../../hooks";
import { SectionSubHeading } from "../../Block/SectionHeading/SectionHeading";
import { videoGalleryItem } from "../../Block/VideoGallery/types";
import Icon from "../../Element/Icon/icon";
import { TextLink } from "../../Element/Typography";
import { BaseTeaserData } from "../../Utility/dataTypes";
import Strip from "./Strip";
import VideoGalleryItem from "./VideoGalleryItem";
import styles from "./videogallerylandscape.module.css";

export type ItemProps = videoGalleryItem & {
  src?: string;
  alt?: string;
  caption?: string;
  sections?: BaseTeaserData["sections"];
  publishedDate?: string;
  srcWidth?: number;
  srcHeight?: number;
  description?: string;
};

export type VideoGalleryLandscapeProps = {
  title: string;
  description?: string;
  items: ItemProps[];
  heading?: {
    leftIcon?: ReactElement;
    rightIcon?: ReactElement;
    withDivider?: boolean;
    hasArrows?: boolean;
  };
  strip?: {
    leftIcon: ReactElement;
    rightIcon: ReactElement;
    dividerVariant?: "primary" | "secondary";
  };
  spotLight: {
    noOfItemsShow?: number;
    leftIcon: ReactElement;
    rightIcon: ReactElement;
    playIcon: ReactElement;
    actionElement: ReactNode;
  };
  mobileLoopingArrowsBelow?: {
    showArrows?: boolean;
    leftIcon: ReactElement;
    rightIcon: ReactElement;
  };
  cta?: {
    name: string;
    url: string;
    icon: ReactNode;
  };
  Imu: ReactNode;
  hasPagination?: boolean;
  hasSummaryItemPublishedDate?: boolean;
};

// const { VideoGalleryLandscapeSmall } = VideoGallery;

/**
 * Description:
 * This component is used when rendering VideoGallery, with Spotlight and Strip.
 * When video in Strip is clicked, the Spotlight will change to the clicked video.
 */
export default function VideoGalleryLandscape({
  title,
  description,
  items,
  heading,
  strip,
  spotLight,
  mobileLoopingArrowsBelow,
  cta,
  Imu,
  hasPagination = true,
  hasSummaryItemPublishedDate = true,
}: VideoGalleryLandscapeProps): ReactElement {
  const [gotoPosition, setGotoPosition] = useState(0);

  const {
    next,
    prev,
    refPassthrough,
    TouchHandlers,
    goto,
    position,
    hasSlideMoved,
    hasReachedEnd,
  } = useCarousel(items.length, 1);

  useEffect(() => {
    if (gotoPosition !== undefined) {
      goto(gotoPosition);
    }
  }, [gotoPosition, goto]);

  const stripPrev = <Icon>{strip?.leftIcon}</Icon>;
  const stripNext = <Icon>{strip?.rightIcon}</Icon>;

  const spotLightPrev = <Icon>{spotLight.leftIcon}</Icon>;
  const spotLightNext = <Icon>{spotLight.rightIcon}</Icon>;

  const showHeadingArrows = !!heading?.hasArrows;

  const HeadingArrows = showHeadingArrows && (
    <Fragment>
      <button className={styles.mDownVisible} onClick={() => prev()}>
        <Icon>{heading?.leftIcon}</Icon>
      </button>
      <button className={styles.mDownVisible} onClick={() => next()}>
        <Icon>{heading?.rightIcon}</Icon>
      </button>
    </Fragment>
  );

  const spotLightNavigation = (
    <Fragment>
      <button
        className={styles.prev}
        style={{
          display: hasSlideMoved ? "block" : "none",
        }}
        onClick={() => prev()}
      >
        {spotLightPrev}
      </button>
      <button
        className={styles.next}
        style={{
          display: hasReachedEnd ? "none" : "block",
        }}
        onClick={() => next()}
      >
        {spotLightNext}
      </button>
    </Fragment>
  );

  return (
    <div className={styles.container}>
      {heading && (
        <div className={styles.headingContainer}>
          <SectionSubHeading
            title={title}
            description={description}
            arrows={HeadingArrows}
            withDivider={heading.withDivider}
          />
        </div>
      )}

      <div className={styles.innerContainer}>
        <div className={styles.carouselContainer}>
          <div className={styles.spotlight}>
            <div
              className={styles.carouselSpotLightContainer}
              {...TouchHandlers}
              ref={refPassthrough}
            >
              {items?.map((item, index: number) => (
                <VideoGalleryItem
                  key={index}
                  item={item}
                  index={index}
                  itemsLength={items.length}
                  position={position}
                  hasPagination={hasPagination}
                  hasSummaryItemPublishedDate={hasSummaryItemPublishedDate}
                />
              ))}
            </div>
            <div className={styles.spotlightNavgiation}>
              {spotLightNavigation}
            </div>
          </div>

          {strip && (
            <Strip
              items={items}
              prevIcon={stripPrev}
              nextIcon={stripNext}
              gotoPosition={position}
              setGotoPosition={setGotoPosition}
              noOfItemsShow={spotLight.noOfItemsShow ?? 4}
              dividerVariant={strip.dividerVariant}
            />
          )}

          {mobileLoopingArrowsBelow?.showArrows && (
            <div className={styles.mobileLoopingArrowsBelow}>
              <button
                className={styles.mobileLoopingArrow}
                onClick={() => prev()}
              >
                {mobileLoopingArrowsBelow.leftIcon}
              </button>
              <button
                className={styles.mobileLoopingArrow}
                onClick={() => next()}
              >
                {mobileLoopingArrowsBelow.rightIcon}
              </button>
            </div>
          )}

          {cta && (
            <div className={styles.ctaContainer}>
              <TextLink href={cta.url}>
                {cta.name}
                {cta.icon}
              </TextLink>
            </div>
          )}
        </div>
        {Imu && <div className={styles.imuContainer}>{Imu}</div>}
      </div>
    </div>
  );
}
