// SocialIcon.tsx
import styles from "./SocialIcon.module.css";
import Icon from "../../Element/Icon/icon"; // Import the Icon component
import * as SocialIcons from "../../Utility/SocialIcons/iconCricle";

export type SocialIconProps = {
  target: string;
  icons: {
    name: string | keyof typeof SocialIcons;
    icon?: React.ReactElement;
    href?: string;
  }[];
  size?: number;
  //This will be the target page url to share
  pageToShare?: {
    url?: string;
    title?: string;
  };
};

export default function SocialIcon({
  target,
  icons,
  size = 44,
  pageToShare,
}: SocialIconProps): React.ReactElement {
  //Retrieve shareable URL
  const shareableURL = (name: string, url?: string, title?: string) => {
    //Lets encode our url
    const encodedUrl = url && encodeURIComponent(url);
    //Lets define our list of brands
    const brands = {
      ["Facebook"]: `https://www.facebook.com/sharer/sharer.php?u=${encodedUrl}`,
      ["Telegram"]: `https://t.me/share/url?url=${encodedUrl}`,
      ["X"]: `https://x.com/intent/tweet?url=${encodedUrl}`,
      ["Whatsapp"]: `https://api.whatsapp.com/send?text=${encodedUrl}`,
      ["LinkedIn"]: `https://www.linkedin.com/sharing/share-offsite/?url=${encodedUrl}`,
      ["Email"]: `mailto://?subject=${encodeURIComponent(title || "")}&body=${encodedUrl}`,
      ["Pinterest"]: `http://pinterest.com/pin/create/button/?url=${encodedUrl}`,
    };

    return (name in brands && brands[name as keyof typeof brands]) || undefined;
  };

  return (
    <div className={styles.socialIconsContainer}>
      {icons.map(({ icon, name, href }, id: number) => {
        const AutoIcon =
          name in SocialIcons && SocialIcons[name as keyof typeof SocialIcons];

        // Now, lets determine our target URL. First, check if pageUrl is not empty and if
        // not then get the socialMedia url using the name as KEY and append the
        // encoded pageUrl. Otherwise, return whatever value from ${href}.
        const targetUrl = pageToShare
          ? shareableURL(name, pageToShare.url, pageToShare.title)
          : href;

        const dataLayoutPosition = pageToShare
          ? "socialshareicon"
          : "socialicon";

        return (
          <a
            key={id}
            href={targetUrl}
            target={target}
            className={styles.socialIcon}
            data-layout-position={dataLayoutPosition}
          >
            <Icon>
              {icon ||
                (AutoIcon && <AutoIcon width={`${size}`} height={`${size}`} />)}
            </Icon>
          </a>
        );
      })}
    </div>
  );
}
