import { Fragment, ReactElement } from "react";

import { useCarousel } from "../../../hooks";
import Divider from "../../Element/Divider/divider";
import ResponsiveImage from "../../Element/ResponsiveImage";
import { VideoSummarySmall } from "../../Element/Typography";
import { VideoGalleryLandscapeProps } from "./VideoGalleryLandscape";
import styles from "./videogallerylandscape.module.css";

const Strip = ({
  items,
  prevIcon,
  nextIcon,
  gotoPosition,
  setGotoPosition,
  noOfItemsShow,
  dividerVariant,
}: {
  items: VideoGalleryLandscapeProps["items"];
  prevIcon: ReactElement;
  nextIcon: ReactElement;
  gotoPosition: number;
  noOfItemsShow: number;
  setGotoPosition: (position: number) => void;
  dividerVariant?: "primary" | "secondary";
}) => {
  const {
    next,
    prev,
    TouchHandlers,
    refPassthrough,
    hasSlideMoved,
    hasReachedEnd,
  } = useCarousel(items.length, noOfItemsShow);

  return (
    <Fragment>
      <div className={styles.strip}>
        <Divider
          size="full"
          orientation="horizontal"
          variant={dividerVariant || "primary"}
        />
        <div
          className={styles.carouselStripContainer}
          {...TouchHandlers}
          ref={refPassthrough}
        >
          {items?.map((item, index) => (
            <div
              key={index}
              onClick={() => setGotoPosition(index)}
              className={`${styles.carouselGalleryItem} ${
                gotoPosition === index && styles.active
              }`}
            >
              <ResponsiveImage
                key={index}
                src={item.src || ""}
                alt={item.alt || ""}
                srcWidth={item.srcWidth || 0}
                srcHeight={item.srcHeight || 0}
                displayWidth={135}
                loading="lazy"
              />
              <div className={styles.lineClamp}>
                <VideoSummarySmall>{item.caption}</VideoSummarySmall>
              </div>
            </div>
          ))}
        </div>
        <Divider size="full" orientation="horizontal" />
        <button
          className={styles.prev}
          onClick={() => prev()}
          style={{
            display: hasSlideMoved ? "block" : "none",
          }}
        >
          {prevIcon}
        </button>
        <button
          className={styles.next}
          onClick={() => next()}
          style={{
            display: hasReachedEnd ? "none" : "block",
          }}
        >
          <div>{nextIcon}</div>
        </button>
      </div>
    </Fragment>
  );
};

export default Strip;
