import { ReactElement } from "react";
import styles from "./divider.module.css"; // Import the CSS module

export type DividerProps = {
  orientation: "horizontal" | "vertical";
  size?: "sm" | "md" | "lg" | "full";
  variant?: "primary" | "secondary";
};

import classNames from "classnames";
import { useCustomClassContext } from "../../../hooks/useCustomClassContext/CustomClassContext";

export default function Divider(props: DividerProps): ReactElement {
  const { orientation, size = "sm", variant = "primary" } = props;

  const isVertical = orientation === "vertical";
  const isHorizontal = orientation === "horizontal";

  const customClass = useCustomClassContext();

  const dividerClasses = classNames(
    styles.divider,
    {
      [styles.vertical]: isVertical,
      [styles.horizontal]: isHorizontal,
      [styles.large]: size === "lg",
      [styles.medium]: size === "md",
      [styles.small]: size === "sm",
      [styles.full]: size === "full",
    },
    variant === "secondary" && customClass?.divider?.secondary,
  );

  return <span className={dividerClasses}></span>;
}
