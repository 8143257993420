import { ReactNode } from "react";
import AffiliateProduct from "../../Block/AffiliatesProduct/affiliatesProduct";
import styles from "./affiliatesProductCarousell.module.css";
import Carousel from "../../Utility/Carousel/Carousel";
import { useMediaQuery } from "../../../hooks";

type AffiliatesProduct = React.ComponentProps<typeof AffiliateProduct>;
type AffiliatesProductCarousellProps = {
  noOfItemsInFrame?: number;
  carouselWidthLeeWay?: number;
  navIcon: {
    next: ReactNode;
    prev: ReactNode;
  };
  items: AffiliatesProduct["item"][];
  itemWidth: AffiliatesProduct["itemWidth"];
  itemHeight: AffiliatesProduct["itemHeight"];
  cta?: AffiliatesProduct["cta"];
};

/**
 * AffiliatesProductCarousell, uses product card component to display products in a carousel.
 *
 */
export default function AffiliatesProductCarousell({
  items,
  navIcon,
  itemWidth,
  itemHeight,
  cta,
  noOfItemsInFrame,
  carouselWidthLeeWay,
}: AffiliatesProductCarousellProps): ReactNode {
  const mobileUp = useMediaQuery("screen and (min-width: 1000px)");
  const noOfItems = noOfItemsInFrame ?? 3;
  const leeWay = carouselWidthLeeWay ?? 30;
  const width = mobileUp ? (itemWidth + leeWay) * noOfItems : 300;
  const itemWidthContainer = mobileUp ? itemWidth : 300;

  const enforceDoublePriceLine = items.some(
    (item) => item.discountPrice && item.originalPrice,
  );
  return (
    <div className={styles.container}>
      <div
        className={styles.carouselContainer}
        style={{
          width: width + "px",
        }}
      >
        <Carousel
          noItemsInFrame={noOfItems}
          itemsLength={items.length}
          nextNav={<>{navIcon.next}</>}
          prevNav={<>{navIcon.prev}</>}
        >
          <div className={styles.itemContainer}>
            {items.map((item, index) => (
              <AffiliateProduct
                cta={cta}
                item={{
                  title: item.title,
                  discountPriceLabel: item.discountPriceLabel,
                  dynamicPricing: item.dynamicPricing,
                  discountPrice: item.discountPrice,
                  originalPriceLabel: item.originalPriceLabel,
                  originalPrice: item.originalPrice,
                  discountInPercentage: item.discountInPercentage,
                  link: item.link,
                  image: item.image,
                }}
                key={index}
                itemWidth={itemWidthContainer}
                itemHeight={itemHeight}
                enforceDoublePriceLine={enforceDoublePriceLine}
              />
            ))}
          </div>
        </Carousel>
      </div>
    </div>
  );
}
