import ErrorLayout from "@app/layouts/ErrorLayout/ErrorLayout";
import TagLayout from "@app/layouts/Tag/TagLayout";
import { SectionData } from "@app/types/ServerCustomContext";
import AdSettings from "@app/utils/AdSettings";
import {
  GA4_REF_CODE_ANCHORBLOCK_B,
  GA4_REF_CODE_CONTENTBLOCK_L,
  GA4_REF_CODE_TITLE_STACKED,
} from "@app/utils/ga/ref-code";
import {
  mapTeaserDataToAnchor,
  mapTeaserDataToLatestProps,
} from "@app/utils/mapTeaserDataToProps";
import MetaData from "@components/Modules/MetaData";
import { mapSectionDataToMetaData } from "@components/Modules/MetaData/mappers";
import PubBaseLayout from "@pub/BaseLayout";
import { configTag } from "@pub/config";
import {
  CustomContext,
  ResponseType,
  TRouteWithRedirect,
  useRouteContext,
} from "@sphtech/web2-core/ssr";
import { useLocation } from "react-router-dom";

export default function Tag() {
  const routeContext: CustomContext<TRouteWithRedirect<SectionData, string>> =
    useRouteContext();

  // Context from server.ts
  const { context: response } = routeContext;
  const location = useLocation();

  if (response.type !== ResponseType.SUCCESS) {
    return <ErrorLayout response={response} />;
  }

  const data = response.payload;
  const topStory = (data.topStories.length && data.topStories[0]) || false;
  const metaData = mapSectionDataToMetaData(data);

  return (
    <PubBaseLayout>
      <MetaData {...metaData} />
      <AdSettings
        pageType="tag"
        sectionName={data.name}
        adUnits="lb1,lb2,imu1,imu2,catfish,prestitial"
        pageTitle={metaData.title}
        currentLocation={location}
      />

      <TagLayout
        data={{
          tagSlug: data.tagSlug,
          name: data.name,
          subHeading: configTag.subHeading,
          anchorBlock: topStory
            ? mapTeaserDataToAnchor(topStory, GA4_REF_CODE_ANCHORBLOCK_B)
            : undefined,
          latestArticle: data.topStories.length
            ? data.topStories
                .slice(1)
                .map((teaser) =>
                  mapTeaserDataToLatestProps(
                    teaser,
                    GA4_REF_CODE_TITLE_STACKED,
                  ),
                )
            : undefined,
          mainContentBlock: data.listedStories.map((teaser) =>
            mapTeaserDataToLatestProps(teaser, GA4_REF_CODE_CONTENTBLOCK_L),
          ),
        }}
      />
    </PubBaseLayout>
  );
}
