import { NoSSR } from "@sphtech/web2-core/components";
import {
  CustomContext,
  ResponseType,
  TRouteWithRedirect,
  useRouteContext,
} from "@sphtech/web2-core/ssr";
import React, { ReactElement } from "react";
import { useBodyScrollLock, useSidebarToggle, useSticky } from "../../../hooks";
import Footer from "../../Module/Footer";
import MegaMenuPortal from "../../Module/MegaMenu/MegaMenuPortal";
import {
  BurgerMenuIcon,
  ButtonWhiteArrowRight,
  LoginIcon,
  SearchIcon,
} from "../../Theme/icons/commonIcons";
import * as Ads from "../../Utility/Ads/Ads";
import { NoAds } from "../../Utility/Ads/AdsSettings";
import { RoutePathsType } from "../../Utility/dataTypes";
import styles from "./base.module.css";
import { GlobalBaseData } from "./types";
import { MegaMenu } from "../../../main";
import { SocialIconProps } from "../../Block/SocialIcon/SocialIcon";
import NavbarGrid from "../../Module/NavbarGrid/NavbarGrid";

type MegaMenuPropsType = React.ComponentProps<typeof MegaMenu>;

export type BaseLayoutProps = {
  ArticleTitle?: string;
  children: React.ReactNode;
  ads?: boolean;
  leaderboard?: boolean;
  NewsTicker?: React.ReactNode;
  RoutePaths: RoutePathsType;
  svgSource: string;
  siteName: string;
  mciText?: React.ReactNode;
  svgHamburgerMenu?: ReactElement;
  socialIconProps?: SocialIconProps;
  megaMenuProps: Partial<MegaMenuPropsType>;
};

/**
 * Items extracted to the /Module/MegaMenu folder:
 * - MegaMenuPortal component
 * - socialMedia constants
 * - NewsLetterProps constants
 * - CloseButtonProps constants
 */
export default function BaseLayout({
  ArticleTitle,
  children,
  ads = true,
  leaderboard = true,
  NewsTicker, // <StNewsticker /> passed as prop for HBS
  RoutePaths, // Routes to be provided by individual pub
  svgSource, // Logo to be provided by individual pub
  siteName, // Used for alt text on Logo
  mciText,
  svgHamburgerMenu, // Used for different svg icon for hamburger menu
  socialIconProps,
  megaMenuProps,
}: BaseLayoutProps): ReactElement {
  const logoSrc = `data:image/svg+xml;utf8,${encodeURIComponent(svgSource)}`;

  const [isOpen, toggleSidebar] = useSidebarToggle(false);
  const { refPassthrough, sticky } = useSticky({
    threshold: 0.06,
  });
  useBodyScrollLock(isOpen);

  let magazineIssues;
  // tmp woraround until fixed upstream in web2-core
  // - useRouteContext always throws if page is static
  try {
    const routeContext: CustomContext<
      TRouteWithRedirect<GlobalBaseData, GlobalBaseData>
      // eslint-disable-next-line react-hooks/rules-of-hooks
    > = useRouteContext();
    const { context: response } = routeContext;
    magazineIssues =
      response.type !== ResponseType.REDIRECT
        ? response.payload?.magazineIssues
        : undefined;
  } catch (e) {
    // nada, e.g. catch all page
  }

  const NavbarObjectProps = {
    ArticleTitle: ArticleTitle,
    toggleSidebar: toggleSidebar,
    routes: RoutePaths.NavBar,
    LogoSource: logoSrc,
    MenuIcon: svgHamburgerMenu || BurgerMenuIcon,
    withSearch: true,
    SearchIcon: SearchIcon,
    LoginIcon: LoginIcon,
  };

  // overwrite text and url from magazine issues config
  const magazineIssuesButton =
    RoutePaths.NavBar.utlityLinks.find(
      (link) => link.id === "magazineIssues",
    ) ||
    RoutePaths.NavBar.primaryLinks.find((link) => link.id === "magazineIssues");
  if (magazineIssuesButton && magazineIssues?.config?.navibar) {
    const { buttonText, buttonUrl } = magazineIssues.config.navibar;
    if (buttonText) {
      magazineIssuesButton.name = buttonText;
    }
    if (buttonUrl) {
      magazineIssuesButton.url = buttonUrl;
    }
  }

  const subscribeImageCrop =
    magazineIssues?.teasers?.length &&
    (magazineIssues.teasers[0].image?.crops.original ||
      magazineIssues.teasers[0].image?.crops.portrait);

  const magazineIssuesMegaMenu = magazineIssues?.config?.megamenu;

  return (
    <>
      {!ads && <NoAds />}
      <header className={styles.header}>
        {!sticky && NewsTicker}
        <NavbarGrid navObject={NavbarObjectProps} sticky={sticky} />
      </header>
      <NoSSR>
        <MegaMenuPortal
          isOpen={isOpen}
          toggleSidebar={toggleSidebar}
          SubscribeBoxProps={
            magazineIssuesMegaMenu?.title && magazineIssuesMegaMenu?.buttonUrl
              ? {
                  description: magazineIssuesMegaMenu.title,
                  buttonText: magazineIssuesMegaMenu.buttonText || "SUBSCRIBE",
                  buttonIcon:
                    magazineIssuesMegaMenu.icon === true &&
                    ButtonWhiteArrowRight,
                  image: subscribeImageCrop ? subscribeImageCrop : undefined,
                  url: magazineIssuesMegaMenu.buttonUrl,
                }
              : undefined
          }
          RoutePaths={RoutePaths}
          socialIconProps={socialIconProps}
          megaMenuProps={megaMenuProps}
        />
      </NoSSR>
      <main>
        {ads && leaderboard && <Ads.AdsLeaderboard />}
        <div ref={refPassthrough}></div>
        {children}
      </main>
      <Footer
        withDivider={true}
        socialIconProps={socialIconProps}
        navLinks={RoutePaths.Footer}
        mciText={mciText}
        logoProps={{
          href: "/",
          src: logoSrc,
          alt: siteName,
        }}
      />
    </>
  );
}
