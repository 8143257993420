import { DealsListApiResponse } from "@app/types/ServerCustomContext";
import { Divider, SectionSubHeading } from "@sphtech/dmg-design-system";
import ProductDeals from "@src/app/components/Modules/Product/ProductDeals";
import useSWR from "swr";

import styles from "../Article/SinglePostLayout.module.css";

type Dimension = {
  width?: number;
  height?: number;
};

type DealsProps =
  | {
      sectionUniqueName: string;
      sectionHeading?: string;
      noOfItemsInFrame?: number;
      dimension?: Dimension;
    }
  | {
      contentListId: string;
      sectionHeading?: string;
      noOfItemsInFrame?: number;
      dimension?: Dimension;
    };
export function Deals(props: DealsProps) {
  const { sectionHeading, noOfItemsInFrame } = props;
  const { data, isLoading } = useSWR<DealsListApiResponse>(
    `/_plat/api/deals_list?${"sectionUniqueName" in props ? `uniqSectionName=${props.sectionUniqueName}` : `contentListId=${props.contentListId}`}`,
  );

  return (
    !isLoading &&
    !!data?.results?.length && (
      <div className={styles.dealsContainer}>
        <div className={styles.dividerSpacing}>
          {sectionHeading ? (
            <SectionSubHeading title={sectionHeading} />
          ) : (
            <Divider orientation="horizontal" size="full" />
          )}
        </div>
        <ProductDeals
          data={{
            items: data.results,
            itemWidth: props.dimension?.width || 180,
            itemHeight: props.dimension?.height,
            noOfItemsInFrame: noOfItemsInFrame || 3,
          }}
        />
        <div className={styles.dividerSpacing}>
          <Divider orientation="horizontal" size="full" />
        </div>
      </div>
    )
  );
}
