import ResponsiveImage from "../../Element/ResponsiveImage";
import ResponsiveIframe from "../../Element/ResponsiveIframe";
import * as Typography from "../../Element/Typography";
import Button from "../../Element/Button";

import { placeholderCrops } from "../../Utility/placeholder";
import ReactPlayerLoader from "@brightcove/react-player-loader";
import { ReactNode } from "react";
import cx from "classnames";

import styles from "./articlemasthead.module.css";
import { ImageCrop } from "../../Utility/dataTypes";
import Divider from "../../Element/Divider/divider";
import { isPortraitVideo } from "../../Element/ResponsiveIframe/utils";

const { Heading, Label, Body } = Typography;

export type ArticleMastHeadProps = {
  category: ReactNode;
  articleHeading: ReactNode;
  excerpt: ReactNode;
  afterDescription?: ReactNode;
  srcWidth?: number;
  srcHeight?: number;
  image?: ImageCrop;
  placeholder?: ImageCrop;
  video?: {
    url?: string;
    id?: number;
    accountid?: number;
  };
  publisher?: ReactNode;
  cta?: {
    vairant?: "primary" | "secondary";
    label: string;
    onClick?: () => void;
    href?: string;
    target?: string;
  };
  isFeatured?: boolean;
  divider?: boolean;
  coverImageCaption?: string;
};

const { Title } = Heading;

/**
 * ArticleMastHead displays the Image if ImageProp is passed otherwise it displays the video in iframe.
 */

export default function ArticleMastHead({
  category,
  articleHeading,
  video,
  afterDescription,
  excerpt,
  image,
  // ToDo refactor to be part of video
  srcWidth = 1,
  srcHeight = 1,
  cta,
  isFeatured = false,
  placeholder = placeholderCrops.landscape,
  divider = false,
  coverImageCaption,
}: ArticleMastHeadProps): ReactNode {
  const isPortaitVideo = video?.url && isPortraitVideo(video.url);

  return (
    <div
      className={cx(
        styles.container,
        styles.gap,
        isPortaitVideo && styles.portraitVideo,
      )}
    >
      <div className={cx(styles.gutter, styles.gap, styles.textContainer)}>
        {divider && (
          <Divider orientation="horizontal" size="full" variant="secondary" />
        )}
        <Label>{category}</Label>
        <Title>{articleHeading}</Title>
        <Body>{excerpt}</Body>
        {afterDescription}
      </div>

      {video ? (
        <div
          className={cx(
            styles.videoContainer,
            isPortaitVideo && styles.portraitVideoContainer,
          )}
        >
          {video.url && <ResponsiveIframe src={video.url} loading="eager" />}
          {video.id && video.accountid && (
            <div className={styles.brightcove}>
              <ReactPlayerLoader
                autoPlay="true"
                attrs={{
                  style: {
                    aspectRatio: srcWidth / srcHeight,
                  },
                }}
                accountId={video.accountid}
                videoId={video.id}
              />
            </div>
          )}
        </div>
      ) : (
        !isFeatured && (
          <figure className={styles.imageFigure}>
            <ResponsiveImage {...(image || placeholder)} displayWidth={1000} />
            {coverImageCaption && (
              <figcaption
                className={cx(styles.imageFigureCaption, styles.gutter)}
              >
                <Typography.Disclaimer>
                  {coverImageCaption}
                </Typography.Disclaimer>
              </figcaption>
            )}
          </figure>
        )
      )}
      {cta && (
        <div className={styles.buttonContainer}>
          <Button
            variant={cta.vairant ?? "primary"}
            onClick={cta.onClick}
            href={cta.href}
            target={cta.target}
          >
            {cta.label}
          </Button>
        </div>
      )}
    </div>
  );
}
