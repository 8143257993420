import {
  PlayButtonWhite,
  whiteArrowLeft,
  whiteArrowRight,
} from "@app/theme/icons/commonIcons";
import {
  Ads,
  Arrow,
  Divider,
  LandscapeScroll,
  ListGroup,
  PortraitScroll,
  Typography,
  VideoGalleryLandscape,
} from "@sphtech/dmg-design-system";
import { NoSSR } from "@sphtech/web2-core/components";
import cx from "classnames";
import { Fragment, ReactNode, useState } from "react";
import { useSearchParams } from "react-router-dom";

import { getNameFromUniq, VIDEO_LANDING_ITEMS } from "./utils";
import styles from "./videolanding.module.css";
import VideoStoryListing from "./VideoStoryListing";

const { Heading } = Typography;

type Props = {
  data: {
    name?: string;
    subHeading?: string;
    anchorListTitle?: string;
    anchorList?: React.ComponentProps<typeof LandscapeScroll>["items"];
    socialListTitle?: string;
    socialList?: React.ComponentProps<typeof PortraitScroll>["items"];

    lb1?: ReactNode;
    lb2?: ReactNode;
  };
};

function VideoLandingLayout({ data }: Props): ReactNode {
  const [searchParams, setSearchParams] = useSearchParams();
  const searchSection = searchParams.get("section");
  const [selectedSection, setSelectedSection] = useState(searchSection);
  const [isLoadingSection, setIsLoadingSection] = useState(false);

  const handleListGroupClick = (e: React.MouseEvent<HTMLAnchorElement>) => {
    if (e.metaKey || e.ctrlKey || e.shiftKey) {
      return;
    }
    e.preventDefault();

    const targetSection =
      new URL(e.currentTarget.href).searchParams.get("section") ?? "";

    // User is going to the same tab
    if (targetSection === searchSection) {
      return;
    }

    // User is going to a different tab
    setIsLoadingSection(true);
    setSelectedSection(targetSection);
    if (targetSection) {
      setSearchParams({ section: targetSection }, { preventScrollReset: true });
    } else {
      // User goes to the All tab
      setSearchParams({}, { preventScrollReset: true });
    }
  };

  return (
    <Fragment>
      {/* Video Information (optional) */}
      <section
        className={cx(styles.bodyContainer, styles.videoLandingContainer)}
      >
        {data.anchorListTitle && (
          <div className={styles.headingContainer}>
            <Heading.SectionTitle>{data.anchorListTitle}</Heading.SectionTitle>
          </div>
        )}

        {data.anchorList && (
          <VideoGalleryLandscape
            title={data.anchorListTitle || "videos"}
            items={data.anchorList}
            spotLight={{
              leftIcon: <Arrow direction="left" />,
              rightIcon: <Arrow />,
              playIcon: PlayButtonWhite,
              actionElement: <div></div>,
            }}
            mobileLoopingArrowsBelow={{
              showArrows: true,
              leftIcon: <Arrow size={22} direction="left" />,
              rightIcon: <Arrow size={22} />,
            }}
            Imu={<Ads.AdsSideBar />}
            hasPagination={false}
            hasSummaryItemPublishedDate={false}
          />
        )}
      </section>

      {data.socialList && (
        <section
          className={cx(styles.bodyContainer, styles.videoLandingContainer)}
        >
          <div className={cx(styles.videoGridContainer, styles.bodyContainer)}>
            {data.socialListTitle && (
              <Heading.SectionTitle>
                {data.socialListTitle}
              </Heading.SectionTitle>
            )}

            <div className={cx(styles.videoLandingPlaylist)}>
              <PortraitScroll
                leftIcon={whiteArrowLeft}
                noOfItemsInView={3}
                rightIcon={whiteArrowRight}
                items={data.socialList}
              />
            </div>
          </div>
        </section>
      )}

      <Ads.AdsMidContent />

      <section
        className={cx(
          styles.bodyContainer,
          styles.videoLandingContainer,
          styles.gutterBottom,
        )}
        data-layout-position="midcontenthub"
      >
        <ListGroup
          activeTitle={getNameFromUniq(selectedSection)}
          onClick={handleListGroupClick}
          items={VIDEO_LANDING_ITEMS}
        />
        <div className={styles.dividerWrapper}>
          <Divider size="full" orientation="horizontal" />
        </div>

        {
          <NoSSR>
            <VideoStoryListing
              data={{
                limit: 9,
                lb1: <Ads.AdsMidContent />,
              }}
              isLoadingSection={isLoadingSection}
              setIsLoadingSection={setIsLoadingSection}
            />
          </NoSSR>
        }
      </section>

      {/* Additional sections... */}
    </Fragment>
  );
}

export default VideoLandingLayout;
