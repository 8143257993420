import { configHome } from "@pub/config";
import { Ads } from "@sphtech/dmg-design-system";
import { Fragment, ReactElement } from "react";

import {
  HomeAffiliateProduct,
  HomeAnchorBlock,
  HomeColumnSideScroll,
  HomeContentBlockA,
  HomeContentBlockB,
  HomeContentBlockD,
  HomeContentBlockF,
  HomeContentBlockG,
  HomeLatestStory,
  HomeMagazineListing,
  HomeNewsletter,
  HomeSocialVideoPortrait,
  HomeVideoGalleryLandscape,
} from "./Elements";
import { HomeLayoutProps } from "./Elements/utils";

export default function HomeLayout({ data }: HomeLayoutProps): ReactElement {
  const homepageElements = {
    ["anchorBlock"]: HomeAnchorBlock,
    ["latestArticle"]: HomeLatestStory,
    ["contentBlockD"]: HomeContentBlockD,
    ["adUnit"]: Ads.AdsMidContent,
    ["socialVideoPortrait"]: HomeSocialVideoPortrait,
    ["affiliateProduct"]: HomeAffiliateProduct,
    ["contentBlockF"]: HomeContentBlockF,
    ["columnSideScroll"]: HomeColumnSideScroll,
    ["contentBlockG"]: HomeContentBlockG,
    ["videoGalleryLandscape"]: HomeVideoGalleryLandscape,
    ["contentBlockB"]: HomeContentBlockB,
    ["contentBlockA"]: HomeContentBlockA,
    ["magazineListing"]: HomeMagazineListing,
    ["newsletter"]: HomeNewsletter,
  };

  return (
    <Fragment>
      {configHome.layoutGrid?.elements.map((element, k) => {
        const ElementComponent = homepageElements[element.name];
        return (
          <ElementComponent
            key={k}
            data={data}
            dataIndex={element.dataIndex}
            alternateBg={element.alternateBg}
          />
        );
      })}
    </Fragment>
  );
}
