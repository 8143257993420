import { Newsletter as NewsletterDesignSystem } from "@sphtech/dmg-design-system";
import React, { ReactNode } from "react";

const SUBSCRIBE_API = "/_plat/api/subscribe_newsletter";

export type AppNewsletterProps = {
  backgroundImage: string;
  newsletterCtaText: string;
  emailPlaceHolder: {
    label?: ReactNode;
    placeholder?: string;
  };
  newsletterBody: string;
  afterTextInput?: (value: string) => void;
  isChecked?: boolean;
};
const AppNewsletter: React.FC<AppNewsletterProps> = (props) => {
  const onSubmit = async (email: string, isChecked: boolean) => {
    try {
      const response = await fetch(SUBSCRIBE_API, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          email: email,
          marketingCheckbox: isChecked,
        }),
      });

      if (response.ok) {
        window.SPHMGTM.cmd.push(
          `send={"type":"event","category":"Newsletter","action":"signup","label":"/"}`,
        );
        return {
          ok: true,
          message: "Thank you for subscribing.",
        };
      } else {
        console.error("Request failed, status:", response.status);
        if (response.status >= 400 && response.status < 500) {
          return {
            ok: false,
            message: "Invalid input, we could not process your request.",
          };
        }
      }
    } catch (error) {
      console.error("Error:", error);
    }
    return {
      ok: false,
      message:
        "An unexpected error occurred. Your request could not be processed at this time.",
    };
  };

  return (
    <NewsletterDesignSystem
      backgroundImage={props.backgroundImage}
      newsletterCtaText={props.newsletterCtaText}
      newsletterBody={props.newsletterBody}
      onSubmit={onSubmit}
      emailPlaceHolder={{
        label: "",
        placeholder: props.emailPlaceHolder.placeholder,
      }}
    />
  );
};

export default AppNewsletter;
