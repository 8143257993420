import { ReactElement, ReactNode } from "react";

import style from "./navlink.module.css";

export type Props = {
  children: ReactNode;
  href: string;
  target?: "_blank";
};

/**
 *
 * Description: [Navgation Link used in the footer or navbar]
 * @param props - The props required by the component (if any)
 * @returns The rendered React element
 */
export default function NavLink({
  children,
  href,
  target,
}: Props): ReactElement {
  return (
    <a className={style.navlink} href={href} target={target}>
      {children}
    </a>
  );
}
