//import design system
import PubBaseLayout from "@pub/BaseLayout";
import {
  CustomContext,
  ResponseType,
  TRouteWithRedirect,
  useRouteContext,
} from "@sphtech/web2-core/ssr";
import MetaData from "@src/app/components/Modules/MetaData";
import ErrorLayout from "@src/app/layouts/ErrorLayout/ErrorLayout";
import LatestStoryLayout from "@src/app/layouts/LatestStory/LatestStoryLayout";
import { SectionData } from "@src/app/types/ServerCustomContext";
import AdSettings from "@src/app/utils/AdSettings";
import {
  GA4_REF_CODE_CONTENTBLOCK_L,
  GA4_REF_CODE_TITLE_STACKED,
} from "@src/app/utils/ga/ref-code";
import { mapTeaserDataToLatestProps } from "@src/app/utils/mapTeaserDataToProps";
import { useLocation } from "react-router-dom";

export default function LatestStory() {
  const routeContext: CustomContext<TRouteWithRedirect<SectionData, string>> =
    useRouteContext();

  // Context from server.ts
  const { context: response } = routeContext;
  const location = useLocation();

  if (response.type !== ResponseType.SUCCESS) {
    return <ErrorLayout response={response} />;
  }

  const data = response.payload;
  return (
    <PubBaseLayout>
      <MetaData type="CollectionPage" title={data.name} path={data.path} />
      <AdSettings
        pageType="latest"
        sectionName="latest-stories"
        adUnits="lb1,lb2,imu1,imu2,catfish,prestitial"
        pageTitle={data.name}
        currentLocation={location}
      />

      <LatestStoryLayout
        data={{
          name: data.name,
          latestArticle: data.topStories.map((teaser) =>
            mapTeaserDataToLatestProps(teaser, GA4_REF_CODE_TITLE_STACKED),
          ),
          mainContentBlock: data.listedStories.map((teaser) =>
            mapTeaserDataToLatestProps(teaser, GA4_REF_CODE_CONTENTBLOCK_L),
          ),
        }}
      />
    </PubBaseLayout>
  );
}
