import React from "react";
import styles from "./youtubefacade.module.css";
import { SOCIAL_MEDIA, getYoutubeId, getAspectRatio } from "./utils";

const YouTubeFacade: React.FC<{
  src: string;
  title?: string;
  onClick: () => void;
}> = ({ src, title, onClick }) => {
  const videoId = getYoutubeId(src);

  return (
    <div className={styles.container}>
      <picture>
        <source
          srcSet={`https://i.ytimg.com/vi_webp/${videoId}/sddefault.webp`}
          type="image/webp"
        />
        <source
          srcSet={`https://i.ytimg.com/vi/${videoId}/hqdefault.jpg`}
          type="image/jpeg"
        />
        <img
          className={styles.facade}
          src={`https://i.ytimg.com/vi/${videoId}/hqdefault.jpg`}
          style={{ aspectRatio: getAspectRatio(src, SOCIAL_MEDIA.YOUTUBE) }}
          alt={title || ""}
          loading="lazy"
        />
      </picture>
      <button className={styles.playButton} type="button" onClick={onClick}>
        <span className={styles.playButtonText}>Play</span>
      </button>
    </div>
  );
};

export default YouTubeFacade;
